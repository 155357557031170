var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"header"},[_c('v-avatar',{attrs:{"tile":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-clock-in")])],1),_c('span',[_vm._v("Zápis práce")])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Datum","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.workRecord.formatedDate),callback:function ($$v) {_vm.$set(_vm.workRecord, "formatedDate", $$v)},expression:"workRecord.formatedDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.workRecord.detail.datePicker),callback:function ($$v) {_vm.$set(_vm.workRecord.detail, "datePicker", $$v)},expression:"workRecord.detail.datePicker"}},[_c('v-date-picker',{attrs:{"max":new Date().toISOString().slice(0, 10)},on:{"input":function($event){_vm.workRecord.detail.datePicker = false}},model:{value:(_vm.workRecord.detail.date),callback:function ($$v) {_vm.$set(_vm.workRecord.detail, "date", $$v)},expression:"workRecord.detail.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-currency-field',{attrs:{"prepend-icon":"mdi-clock-fast","type":"tel","label":"Počet hodin","suffix":"hod"},model:{value:(_vm.workRecord.detail.hoursSpent),callback:function ($$v) {_vm.$set(_vm.workRecord.detail, "hoursSpent", $$v)},expression:"workRecord.detail.hoursSpent"}})],1),(!_vm.project)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"item-text":"Name","item-value":"Id","items":_vm.projects,"label":"Projekt"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.ContactName)+" - "+_vm._s(item.Name))]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.ContactName)+" - "+_vm._s(item.Name))]}}],null,false,1155120668),model:{value:(_vm.workRecord.detail.projectId),callback:function ($$v) {_vm.$set(_vm.workRecord.detail, "projectId", $$v)},expression:"workRecord.detail.projectId"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"disabled":!_vm.workRecord.detail.projectId,"item-text":"Name","item-value":"Id","items":_vm.workRecords.workTypes.items,"label":"Typ práce"},model:{value:(_vm.workRecord.detail.workTypeId),callback:function ($$v) {_vm.$set(_vm.workRecord.detail, "workTypeId", $$v)},expression:"workRecord.detail.workTypeId"}})],1),(!_vm.ticket)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Číslo tiketu"},model:{value:(_vm.workRecord.detail.ticketId),callback:function ($$v) {_vm.$set(_vm.workRecord.detail, "ticketId", $$v)},expression:"workRecord.detail.ticketId"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"label":"K fakturaci"},model:{value:(_vm.workRecord.detail.isBillable),callback:function ($$v) {_vm.$set(_vm.workRecord.detail, "isBillable", $$v)},expression:"workRecord.detail.isBillable"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Popis"},model:{value:(_vm.workRecord.detail.note),callback:function ($$v) {_vm.$set(_vm.workRecord.detail, "note", $$v)},expression:"workRecord.detail.note"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":!_vm.workRecord.isValid || _vm.application.loading,"color":"#0070c0","depressed":"","small":"","dark":""},on:{"click":_vm.save}},[_vm._v("Uložit práci")]),_c('v-btn',{attrs:{"disabled":_vm.application.loading,"color":"secondary","small":"","text":""},on:{"click":_vm.cancel}},[_vm._v("Zpět")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }