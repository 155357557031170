import { authHeader } from '@/helpers';
import { handleResponse } from './base.service';

export const projectService = {
  getAll,
  getById,
  edit,
  addUser,
  removeUser,
  createProject
};

async function getAll(): Promise<any> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Projects`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (!handledResponse.needsReload) {
    localStorage.setItem('projects', JSON.stringify(handledResponse.data));
    return await handledResponse.data;
  } else {
    return await getAll();
  }
}

async function getById(id: any): Promise<any> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Projects/${id}`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (!handledResponse.needsReload) {
    return await handledResponse.data;
  } else {
    return await getById(id);
  }
}

async function edit(form: any, projectId: number) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(form)
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Projects/${projectId}`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (handledResponse.needsReload) {
    await edit(form, projectId);
  }
}

async function addUser(projectId: number, userId: number) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader()
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Projects/${projectId}/AddUser/${userId}`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (handledResponse.needsReload) {
    await addUser(projectId, userId);
  }
}

async function removeUser(projectId: number, userId: number) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Projects/${projectId}/RemoveUser/${userId}`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (handledResponse.needsReload) {
    await removeUser(projectId, userId);
  }
}

async function createProject(form: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(form)
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Projects`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (handledResponse.needsReload) {
    await createProject(form);
  }
}
