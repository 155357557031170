















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ProjectDetail } from '../../models/project-detail.model';
import WorkRecordTable from '../../components/WorkRecordTable.vue';

@Component({
  components: {
    WorkRecordTable
  }
})
export default class ProjectDetailComponent extends Vue {
  account: any = this.$store.state.account;
  projects: any = this.$store.state.projects;
  users: any = this.$store.state.users;
  contacts: any = this.$store.state.contacts;
  application: any = this.$store.state.application;
  projectDetail: ProjectDetail = new ProjectDetail();
  editUser: any = null;
  dialog: boolean = false;
  search = '';

  created(): void {
    (this as any).$store.dispatch('projects/getById', this.$route.params.id);
    (this as any).$store.dispatch('users/getAll');
    (this as any).$store.dispatch('contacts/getAll');
  }

  updated() {
    this.$recaptchaInstance?.hideBadge();
  }

  isUserAssigned(id: number) {
    return this.projects.detail.Users.find((user: any) => user.Id === id);
  }

  openDialog(userId: any) {
    this.editUser = userId;
    this.dialog = true;
  }

  submitUserChange() {
    const projectId = this.projects?.detail?.Id;
    const userId = this.editUser;

    if (this.isUserAssigned(this.editUser)) {
      (this as any).$store.dispatch('projects/removeUser', { projectId, userId });
    } else {
      (this as any).$store.dispatch('projects/addUser', { projectId, userId });
    }

    this.editUser = null;
    this.dialog = false;
  }

  closeDialog() {
    this.editUser = null;
    this.dialog = false;
  }

  submit(): void {
    (this.$refs.projectDetail as any).validate();

    if (this.projectDetail.valid) {
      const form = {
        Name: this.projectDetail.name.value,
        ContactId: this.projectDetail.contactId.value
      };
      const projectId = this.projects?.detail?.Id;

      (this as any).$store.dispatch('projects/edit', { form, projectId });
    }
  }

  public beforeDestroy() {
    (this as any).$store.commit('projects/clearProject');
  }

  get projectId() {
    return this.projects?.detail?.Id;
  }

  @Watch('projectId')
  onUserLoad(value: string, oldValue: string) {
    this.projectDetail.name.value = this.projects.detail.Name;
    this.projectDetail.contactId.value = this.projects.detail.ContactId;
  }

  get breadcrumbs() {
    return [
      {
        text: 'Projekty',
        disabled: false,
        to: '/projects'
      }
    ];
  }
}
