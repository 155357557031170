


































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { router } from '../../helpers';
import { ValidationService } from '../../services';
import TicketEdit from './../../components/TicketEdit.vue';
import { TicketDetail } from '../../models/ticket-detail.model';
import WorkRecordTable from '../../components/WorkRecordTable.vue';
import WorkRecordEdit from '../../components/WorkRecordEdit.vue';
// @ts-ignore
import { TiptapVuetify } from 'tiptap-vuetify';
import { WorkRecordsList } from '@/models';

@Component({
  components: {
    TicketEdit,
    TiptapVuetify,
    WorkRecordTable,
    WorkRecordEdit
  }
})
export default class TicketDetailComponent extends Vue {
  account: any = this.$store.state.account;
  tickets: any = this.$store.state.tickets;
  application: any = this.$store.state.application;
  ticketDetail: TicketDetail = new TicketDetail();
  fab: boolean = false;
  tab: string = '1';
  showEdit: boolean = false;
  showAddMessage: boolean = false;
  showUpload: boolean = false;
  showDelete: boolean = false;
  showAdminSection: boolean = false;
  fileToDelete: any = null;
  value: string = 'recent';
  editSolver: boolean = false;
  editState: boolean = false;
  editPriority: boolean = false;
  addMessage: boolean = false;
  showEditTitle: boolean = false;
  titleEdit: string = '';
  showEditContent: boolean = false;
  contentEdit: string = '';
  showWorkEdit: boolean = false;
  workRecordsList: WorkRecordsList = new WorkRecordsList();

  created(): void {
    (this as any).$store.dispatch('tickets/getById', this.$route.params.id);
  }

  updated() {
    this.$recaptchaInstance?.hideBadge();
  }

  cancel(): void {
    router.push(`/`);
  }

  saveMessage(): void {
    const messageText = this.ticketDetail.message.value;
    if (messageText) {
      (this as any).$store.dispatch('tickets/saveMessage', { messageText }).then(() => {
        this.ticketDetail.message.value = '';
        this.showAddMessage = false;
        this.addMessage = false;
      });
    }
  }

  saveTicket(): void {
    const ticket = this.tickets.detail;
    (this as any).$store.dispatch('tickets/saveTicket', { ticket }).then(() => {
      this.showEdit = false;
    });
  }

  cancelTicketEdit(): void {
    (this as any).$store.dispatch('tickets/getById', this.$route.params.id).then(() => {
      this.showEdit = false;
    });
  }

  addDropFile(files: any): void {
    files.dataTransfer.files.forEach((file: any) => {
      this.ticketDetail.files.push(file);
    });
  }

  upload(): void {
    const ticketId = this.tickets.detail.Id;
    const files = this.ticketDetail.files;
    (this as any).$store.dispatch('tickets/uploadAttachments', { ticketId, files }).then(() => {
      this.ticketDetail.files = [];
      this.showUpload = false;
    });
  }

  handleDownloadAttachment(trustedFileName: string, fileName: string): void {
    const ticketId = this.tickets.detail.Id;
    (this as any).$store.dispatch('tickets/downloadAttachment', { ticketId, trustedFileName, fileName });
  }

  handleDeleteAttachment(): void {
    const ticketId = this.tickets.detail.Id;
    const trustedFileName = this.fileToDelete.TrustedFileName;
    (this as any).$store.dispatch('tickets/deleteAttachment', { ticketId, trustedFileName }).then(() => {
      this.showDelete = false;
    });
  }

  openDeleteDialog(file: any): void {
    this.fileToDelete = file;
    this.showDelete = true;
  }

  closeDeleteDialog(): void {
    this.fileToDelete = null;
    this.showDelete = false;
  }

  validateFile(files: any): void {
    const error: any = [];

    files.forEach((file: any, index: number) => {
      const isValid = false;

      if (file.size > 2097152) {
        error.push(`${file.name}: (size) removed`);
        delete this.ticketDetail.files[index];
      } else {
        ValidationService.isFileValid(file, (isValid: boolean) => {
          if (!isValid) {
            error.push(`Zvolený dokument ${file.name} má nepodporovaný formát.`);
            delete this.ticketDetail.files[index];
          }
        });
      }
    });

    this.ticketDetail.files = this.ticketDetail.files.filter((file: any) => !!file);
    this.ticketDetail.errorMessages = error.join(', ');
  }

  editSolverChange() {
    this.editState = false;
    this.editPriority = false;
    this.editSolver = true;
  }

  editStateChange() {
    this.editPriority = false;
    this.editSolver = false;
    this.editState = true;
  }

  editPriorityChange() {
    this.editSolver = false;
    this.editState = false;
    this.editPriority = true;
  }

  onClickOutsidePriority() {
    this.editPriority = false;
  }

  onClickOutsideState() {
    this.editState = false;
  }

  onClickOutsideSolver() {
    this.editSolver = false;
  }

  addMessageClick() {
    this.addMessage = true;
  }

  onClickOutsideMessage() {
    this.addMessage = false;
  }

  save() {
    this.editSolver = false;
    this.editPriority = false;
    this.editState = false;
    this.saveTicket();
  }

  saveAdminSection() {
    this.saveTicket();
    this.showAdminSection = false;
  }

  openEditTitle() {
    this.showEditTitle = true;
    this.titleEdit = this.tickets.detail.Title;
  }

  closeEditTitle() {
    this.showEditTitle = false;
    this.titleEdit = '';
  }

  saveEditTitle() {
    if (this.titleEdit.trim() !== '') {
      this.showEditTitle = false;
      this.tickets.detail.Title = this.titleEdit.trim();
      this.titleEdit = '';
      this.saveTicket();
    }
  }

  openEditContent() {
    this.showEditContent = true;
    this.contentEdit = this.tickets.detail.Content;
  }

  closeEditContent() {
    this.showEditContent = false;
    this.contentEdit = '';
  }

  saveEditConent() {
    if (this.contentEdit.trim() !== '') {
      this.showEditContent = false;
      this.tickets.detail.Content = this.contentEdit.trim();
      this.contentEdit = '';
      this.saveTicket();
    }
  }

  selectWorkRecord(item: any): void {
    this.workRecordsList.detail.id = item.Id;
    this.workRecordsList.detail.date = item.Date.slice(0, 10);
    this.workRecordsList.detail.hoursSpent = item.HoursSpent;
    this.workRecordsList.detail.isBillable = item.IsBillable;
    this.workRecordsList.detail.note = item.Note;
    this.workRecordsList.detail.projectId = item.Project.Id;
    this.workRecordsList.detail.userId = item.User.Id;
    this.workRecordsList.detail.ticketId = item.TicketId;
    this.workRecordsList.detail.workTypeId = item.WorkType.Id;

    this.showWorkEdit = true;
  }

  saveWorkRecord() {
    if (this.workRecordsList.detail.id !== null) {
      const newWorkRecord = this.workRecordsList.detail;
      (this as any).$store.dispatch('workRecords/updateWorkRecord', { newWorkRecord, searchType: 'ticket' });
    } else {
      const newWorkRecord = this.workRecordsList.detail;
      newWorkRecord.userId = this.account.user.UserId;

      (this as any).$store.dispatch('workRecords/createWorkRecord', { newWorkRecord, searchType: 'ticket' });
    }

    this.showWorkEdit = false;
    this.clearWorkEditDetail();
  }

  closeWorkRecord() {
    this.showWorkEdit = false;
    this.clearWorkEditDetail();
  }

  clearWorkEditDetail() {
    this.workRecordsList.detail = {
      datePicker: false,
      date: '',
      hoursSpent: null,
      isBillable: true,
      note: '',
      projectId: null,
      userId: null,
      ticketId: null,
      workTypeId: null,
      id: null
    };
  }

  public beforeDestroy() {
    this.tickets.detail = null;
  }

  get messages() {
    return this.tickets.detail.Messages;
  }

  get isUserInternal() {
    return !this.account.user?.User.IsExternal || this.account.user?.User.Role === 'Administrator';
  }

  get isAdmin() {
    return this.account.user?.User.Role === 'Administrator';
  }

  get breadcrumbs() {
    return [
      {
        text: 'Tikety',
        disabled: false,
        to: '/'
      }
    ];
  }
}
