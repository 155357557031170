











































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { UserDetail } from '../../models/user-detail.model';
import WorkRecordTable from '../../components/WorkRecordTable.vue';

@Component({
  components: {
    WorkRecordTable
  }
})
export default class UserDetailComponent extends Vue {
  account: any = this.$store.state.account;
  users: any = this.$store.state.users;
  application: any = this.$store.state.application;
  userDetail: UserDetail = new UserDetail();
  projects: any = this.$store.state.projects;
  editProject: any = null;
  dialog: boolean = false;
  emailRules = [(v: string) => !!v || 'Pole je povinné', (v: string) => /.+@.+\..+/.test(v) || 'Uživatelské jméno musí být email'];
  search = '';

  created(): void {
    (this as any).$store.dispatch('users/getById', this.$route.params.id);
    (this as any).$store.dispatch('projects/getAll');
  }

  updated() {
    this.$recaptchaInstance?.hideBadge();
  }

  public beforeDestroy() {
    (this as any).$store.commit('users/clearUser');
  }

  get userId() {
    return this.users?.detail?.Id;
  }

  @Watch('userId')
  onUserLoad(value: string, oldValue: string) {
    this.userDetail.firstName.value = this.users.detail.FirstName;
    this.userDetail.lastName.value = this.users.detail.LastName;
    this.userDetail.userName.value = this.users.detail.UserName;
    this.userDetail.role.value = this.users.detail.Role;
    this.userDetail.isExternal = this.users.detail.IsExternal;
  }

  submit(): void {
    (this.$refs.userDetail as any).validate();
    //this.userDetail.customValidate();

    if (this.userDetail.valid) {
      const form = {
        FirstName: this.userDetail.firstName.value,
        LastName: this.userDetail.lastName.value,
        UserName: this.userDetail.userName.value,
        Role: this.userDetail.role.value,
        IsExternal: this.userDetail.isExternal
      };
      const userId = this.users?.detail?.Id;

      (this as any).$store.dispatch('users/edit', { form, userId });
    }
  }

  isProjectAssigned(id: number) {
    return this.users.detail.Projects.find((project: any) => project.Id === id);
  }

  openDialog(projectId: any) {
    this.editProject = projectId;
    this.dialog = true;
  }

  submitProjectChange() {
    const userId = this.users?.detail?.Id;
    const projectId = this.editProject;

    if (this.isProjectAssigned(this.editProject)) {
      (this as any).$store.dispatch('users/removeProject', { userId, projectId });
    } else {
      (this as any).$store.dispatch('users/addProject', { userId, projectId });
    }

    this.editProject = null;
    this.dialog = false;
  }

  closeDialog() {
    this.editProject = null;
    this.dialog = false;
  }

  get breadcrumbs() {
    return [
      {
        text: 'Uživatelé',
        disabled: false,
        to: '/users'
      }
    ];
  }
}
