import { authHeader, authHeaderEmptyContentType } from '@/helpers';
import { handleResponse } from './base.service';

export const ticketService = {
  search,
  getById,
  getHistory,
  saveMessage,
  saveTicket,
  createTicket,
  uploadAttachments,
  downloadAttachment,
  deleteAttachment
};

async function search(searchModel: any): Promise<any> {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(searchModel)
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Tickets/Search`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (!handledResponse.needsReload) {
    return await handledResponse.data;
  } else {
    return await search(searchModel);
  }
}

async function getById(id: any): Promise<any> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Tickets/${id}`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (!handledResponse.needsReload) {
    localStorage.setItem('ticket', JSON.stringify(handledResponse.data));

    return await handledResponse.data;
  } else {
    return await getById(id);
  }
}

async function getHistory(id: any): Promise<any> {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Tickets/${id}/history`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (!handledResponse.needsReload) {
    return await handledResponse.data;
  } else {
    return await getHistory(id);
  }
}

async function saveMessage(userId: number, ticketId: number, message: string) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      CreatedById: userId,
      Message: message
    })
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Tickets/${ticketId}/messages`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (handledResponse.needsReload) {
    await saveMessage(userId, ticketId, message);
  }
}

async function saveTicket(ticket: any) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({
      ProjectId: ticket.Project.Id,
      PriorityId: getIdByName(ticket.AvailableStates.Priority, ticket.Priority),
      StatusId: getIdByName(ticket.AvailableStates.Status, ticket.Status),
      SolverId: getIdByName(ticket.AvailableStates.Solver, ticket.Solver),
      Title: ticket.Title,
      Content: ticket.Content,
      InternalMessage: ticket.internalMessage,
      Type: ticket.type,
      TimeBudget: ticket.timeBudget,
      PriceBudget: ticket.priceBudget
    })
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Tickets?id=${ticket.Id}`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (handledResponse.needsReload) {
    await saveTicket(ticket);
  }
}

async function createTicket(newTicket: any) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      ProjectId: newTicket.project.value,
      Title: newTicket.title.value,
      Message: newTicket.message.value,
      AuthorId: newTicket.authorId
    })
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Tickets`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (handledResponse.needsReload) {
    await createTicket(newTicket);
  }
}

async function uploadAttachments(ticketId: number, files: any): Promise<any> {
  const headers = authHeaderEmptyContentType();

  var formData = new FormData();
  for (const file of files) {
    formData.append('files', file, file.name);
  }

  const requestOptions = {
    method: 'POST',
    headers: headers,
    body: formData
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Tickets/${ticketId}/attachments`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (!handledResponse.needsReload) {
    return await handledResponse;
  } else {
    return await uploadAttachments(ticketId, files);
  }
}

async function deleteAttachment(ticketId: number, trustedFileName: string) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  const response = await fetch(`${process.env.VUE_APP_API}/api/Tickets/${ticketId}/attachments/${trustedFileName}`, requestOptions as any);
  const handledResponse = await handleResponse(response);

  if (handledResponse.needsReload) {
    await deleteAttachment(ticketId, trustedFileName);
  }
}

function downloadAttachment(ticketId: number, trustedFileName: string, fileName: string) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  fetch(`${process.env.VUE_APP_API}/api/Tickets/${ticketId}/attachments/${trustedFileName}`, requestOptions as any)
    .then(response => response.blob())
    .then(blob => {
      var a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.setAttribute('download', fileName);
      a.click();
    });
}

function getIdByName(array: any, name: string) {
  if (array.length && name != null) {
    return array.find((object: any) => object.Name === name).Id;
  } else {
    return null;
  }
}
