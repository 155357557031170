








































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { router } from '../../helpers';
import { TicketDetail } from '../../models/ticket-detail.model';
// @ts-ignore
import { TiptapVuetify } from 'tiptap-vuetify';

@Component({
  components: {
    TiptapVuetify
  }
})
export default class TicketCreateComponent extends Vue {
  account: any = this.$store.state.account;
  projects: any = this.$store.state.projects;
  application: any = this.$store.state.application;
  ticketDetail: TicketDetail = new TicketDetail();
  checked: any = false;

  created() {
    (this as any).$store.dispatch('projects/getAll');
  }

  updated() {
    this.$recaptchaInstance?.hideBadge();
  }

  handleCreateSubmit() {
    (this.$refs.form as any).validate();
    if (this.ticketDetail.valid) {
      const newTicket = this.ticketDetail;

      if (!this.ticketDetail.authorId) {
        newTicket.authorId = this.account.user.UserId;
      }

      (this as any).$store.dispatch('tickets/createTicket', { newTicket });
    }
  }
  handleCancelSubmit() {
    router.push(`/`);
  }

  get breadcrumbs() {
    return [
      {
        text: 'Tikety',
        disabled: false,
        to: '/'
      }
    ];
  }

  get projectId() {
    return this.ticketDetail.project.value;
  }

  @Watch('projectId')
  onProjectIdChanged(value: number) {
    if (value) {
      (this as any).$store.dispatch('projects/getById', value);
    }
  }
}
