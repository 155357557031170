
































































import { WorkRecordsList } from '@/models';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class WorkRecordTable extends Vue {
  workRecords: any = this.$store.state.workRecords;
  application: any = this.$store.state.application;
  workRecordsList: WorkRecordsList = new WorkRecordsList();
  datePicker: boolean = false;

  @Prop()
  workRecord: any;
  @Prop()
  projects: any;
  @Prop()
  projectId: any;
  @Prop()
  userId: any;
  @Prop()
  ticketId: any;
  @Prop()
  searchType: any;

  created() {
    if (this.userId) {
      this.search().userId = this.userId;
    }

    if (this.ticketId) {
      this.search().ticketId = this.ticketId;
    }

    if (this.projectId) {
      this.search().projectId = this.projectId;
    }

    this.search().actualPage = 1;
    this.loadWorkRecords();
  }

  select(item: any) {
    this.$emit('select', item);
  }

  onPage(page: number): void {
    this.search().actualPage = page;
    this.loadWorkRecords();
  }
  onSort(field: any): void {
    this.search().actualPage = 1;
    this.search().orderBy = field[0];
    this.loadWorkRecords();
  }

  onSortDesc(desc: any): void {
    this.search().actualPage = 1;
    this.search().orderByAsc = !desc[0];
    this.loadWorkRecords();
  }
  onItemsPerPage(field: any): void {
    this.search().actualPage = 1;
    this.search().pageRows = field === -1 ? this.workRecords.totalCount : field;
    this.loadWorkRecords();
  }

  loadWorkRecords(): void {
    const searchQuery = this.search();
    const searchType = this.searchType;

    (this as any).$store.dispatch('workRecords/getAll', { searchQuery, searchType });
  }

  filterByProject() {
    this.workRecords.search.actualPage = 1;
    this.loadWorkRecords();
  }

  selectDate() {
    this.search().actualPage = 1;
    (this.$refs.dialog as any).save(this.search().dates);
    this.loadWorkRecords();
  }

  search() {
    return this.workRecords.search[this.searchType];
  }
}
