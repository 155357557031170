export const translations = {
  cs: {
    status: {
      InProgress: 'V řešení',
      New: 'Založený',
      Finished: 'Dokončený',
      Closed: 'Uzavřený'
    },
    priority: {
      Low: 'Nízká',
      Normal: 'Normální',
      High: 'Vysoká'
    }
  }
};
