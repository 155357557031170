


























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MenuComponent from './components/Menu.vue';
import { Menu } from './models/menu.model';

@Component({
  components: {
    MenuComponent
  }
})
export default class App extends Vue {
  account: any = this.$store.state.account;
  state: any = this.$store.state.alert;
  application: any = this.$store.state.application;
  snackbar: boolean = false;
  snackbarText: string = '';
  snackbarSeverity: string = 'success';
  menu: Menu = new Menu();

  @Watch('state', { immediate: true, deep: true })
  onStateChange(newVal: any) {
    if (newVal.message) {
      this.snackbar = true;
      this.snackbarText = newVal.message;
      this.snackbarSeverity = newVal.type;
    }
  }

  toggleMenu() {
    (this as any).$store.dispatch('application/toggleMenu');
  }
}
